import { combineReducers } from 'redux';

import { appReducer } from './app.reducer';
import { clienteReducer } from './cliente.reducer';
import { detalleReducer } from './detalle.reducer';

const reducers = combineReducers({
  app: appReducer,
  cliente: clienteReducer,
  detalle: detalleReducer,
});

export default reducers;
