import { api } from '../axios/api';
import { servicios, constantes } from '../helpers/index';
import { clienteModel } from '../models/cliente.model';
import { mensaje } from '../../commons/Mensaje';

export const CLIENTE_PROCESS = '[Cliente] Cliente procesando';
export const CLIENTE_SAVE_SUCCESS = '[Cliente] Cliente guardar exito';
export const CLIENTE_LIST_SUCCESS = '[Cliente] Cliente lista exito';
export const CLIENTE_LIST_FAIL = '[Cliente] Cliente lista error';
export const CLIENTE_SELECTED = '[Cliente] Cliente seleccionado';
export const CLIENTE_CLOSE = '[Cliente] Cliente cerrar modal';
export const CLIENTE_FILTER = '[Cliente] Cliente filtros';
export const CLIENTE_FAIL = '[Cliente] Cliente proceso error';

import {
  LOGIN_ERROR,
  CAPTCHA_SUCCESS,
  APP_PROCESS,
  putCaptchaAction,
} from './app.action';

export const postClienteAction = (item) => (dispatch, getState) => {
  let { captcha } = getState().app;

  dispatch({
    type: CLIENTE_PROCESS,
  });

  dispatch({
    type: APP_PROCESS,
  });

  let cliente = {
    nn: item.nn,
    ap: item.ap,
    cu: item.cu,
    pc: item.pc,
    rp: item.rp,
    ct: captcha.ct,
    tx: item.tx,
    ca: constantes.APLICACION,
  };

  api
    .post(servicios.CLIENTE, cliente)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch({ type: CLIENTE_SAVE_SUCCESS });
    })
    .catch((error) => {
      if (error.data.errors) {
        if (error.data.errors.img) {
          dispatch({
            type: CLIENTE_FAIL,
            payload: clienteModel,
          });
          dispatch({
            type: LOGIN_ERROR,
            payload: 'Caracteres incorrectos',
          });
          dispatch({
            type: CAPTCHA_SUCCESS,
            payload: {
              ct: error.data.errors.ct,
              si: error.data.errors.si,
              img: error.data.errors.img,
            },
          });
        } else {
          dispatch({
            type: CLIENTE_FAIL,
            payload: error.data.errors,
          });
          dispatch({
            type: LOGIN_ERROR,
            payload: null,
          });
          dispatch(putCaptchaAction(captcha.ct));
        }
      } else {
        dispatch({
          type: CLIENTE_FAIL,
          payload: clienteModel,
        });
        dispatch({
          type: LOGIN_ERROR,
          payload: null,
        });
        dispatch(putCaptchaAction(captcha.ct));
      }
    });
};

export const patchReseteoAction = (item) => (dispatch, getState) => {
  let { captcha } = getState().app;

  dispatch({
    type: CLIENTE_PROCESS,
  });

  dispatch({
    type: APP_PROCESS,
  });

  let cliente = {
    cu: item.cu,
    ct: captcha.ct,
    tx: item.tx,
  };

  api
    .patch(`${servicios.CLIENTE}/reseteo`, cliente)
    .then(() => {
      mensaje('Operación exitosa, revise su correo electrónico');
      dispatch({ type: CLIENTE_SAVE_SUCCESS });
    })
    .catch((error) => {
      if (error.data.errors) {
        if (error.data.errors.img) {
          dispatch({
            type: CLIENTE_FAIL,
            payload: clienteModel,
          });
          dispatch({
            type: LOGIN_ERROR,
            payload: 'Caracteres incorrectos',
          });
          dispatch({
            type: CAPTCHA_SUCCESS,
            payload: {
              ct: error.data.errors.ct,
              si: error.data.errors.si,
              img: error.data.errors.img,
            },
          });
        } else {
          dispatch({
            type: CLIENTE_FAIL,
            payload: error.data.errors,
          });
          dispatch({
            type: LOGIN_ERROR,
            payload: null,
          });
          dispatch(putCaptchaAction(captcha.ct));
        }
      } else {
        dispatch({
          type: CLIENTE_FAIL,
          payload: clienteModel,
        });
        dispatch({
          type: LOGIN_ERROR,
          payload: null,
        });
        dispatch(putCaptchaAction(captcha.ct));
      }
    });
};

export const patchRestablecerAction = (item) => (dispatch, getState) => {
  let { captcha } = getState().app;

  dispatch({
    type: CLIENTE_PROCESS,
  });

  dispatch({
    type: APP_PROCESS,
  });

  let cliente = {
    us: item.us,
    cu: item.cu,
    nu: item.nu,
    nc: item.cn,
    cv: item.cv,
    ct: captcha.ct,
    tx: item.tx,
  };

  api
    .patch(`${servicios.CLIENTE}/restablecer`, cliente)
    .then(() => {
      mensaje(
        'Operación exitosa, ya puede iniciar sesión con su nueva contraseña'
      );
      dispatch({ type: CLIENTE_SAVE_SUCCESS });
    })
    .catch((error) => {
      if (error.data.errors) {
        if (error.data.errors.img) {
          dispatch({
            type: CLIENTE_FAIL,
            payload: clienteModel,
          });
          dispatch({
            type: LOGIN_ERROR,
            payload: 'Caracteres incorrectos',
          });
          dispatch({
            type: CAPTCHA_SUCCESS,
            payload: {
              ct: error.data.errors.ct,
              si: error.data.errors.si,
              img: error.data.errors.img,
            },
          });
        } else {
          dispatch({
            type: CLIENTE_FAIL,
            payload: error.data.errors,
          });
          dispatch({
            type: LOGIN_ERROR,
            payload: null,
          });
          dispatch(putCaptchaAction(captcha.ct));
        }
      } else {
        dispatch({
          type: CLIENTE_FAIL,
          payload: clienteModel,
        });
        dispatch({
          type: LOGIN_ERROR,
          payload: null,
        });
        dispatch(putCaptchaAction(captcha.ct));
      }
    });
};
