import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Texto from '../commons/Texto';
import Boton from '../commons/Boton';
import Captcha from '../components/Captcha';

import { patchReseteoAction } from '../redux/actions/cliente.action';

import '../css/pages/login.css';
import { NavLink } from 'react-router-dom';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const Olvido = () => {
  const [{ cu, tx }, setUser] = useState({ us: null, tx: null });
  const dispatch = useDispatch();
  const { error, processing } = useSelector((state) => state.app);

  const handleChange = (item) => {
    const { name, value } = item;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLogin = () => {
    dispatch(patchReseteoAction({ cu, tx }));
    setUser((prev) => ({
      ...prev,
      tx: '',
    }));
  };

  return (
    <div className="cb-content-login">
      <div className="cb-bloque cb-fondo">
        <label>Todos los derechos reservados 2021. Finanser.</label>
      </div>
      <div className="cb-bloque cb-login">
        <div className="content">
          <div className="logo"></div>
          <p className="icono"></p>
          <label className="title">Recuperar contrase&ntilde;a</label>
          <span className="sub-title">
            Ingresa tu correo electr&oacute;nico con el cual te registraste.
          </span>
          <Texto
            name="cu"
            value={cu}
            label="Correo electr&oacute;nico"
            tabIndex={1}
            onChange={handleChange}
            autoFocus
          />
          <Captcha value={tx} onChange={handleChange} tabIndex={2} />
          <Boton
            label="Enviar"
            disabled={processing}
            onClick={handleLogin}
            tabIndex={3}
          />
          {error && <label className="cb-error">{error}</label>}
          <NavLink to="/login" className="texto" style={{ marginTop: '30px' }}>
            <ChevronLeftIcon /> Atras
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Olvido;
