import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ children, ...rest }) => {
  let currentUser = localStorage.getItem('currentUser');
  return (
    <Route
      {...rest}
      render={() => (currentUser ? children : <Redirect to={'/'} />)}
    />
  );
};

export default PrivateRoute;
