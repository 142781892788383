import React from 'react';

const IconoText = (props) => {
  return (
    <div className="cb-global-icono-text">
      <div
        className="icono"
        style={{ backgroundImage: `url('${props.img}')` }}
      ></div>
      <div className="texto">
        <span>{props.nombre}</span>
        <span>{props.texto}</span>
      </div>
    </div>
  );
};

export default IconoText;
