import React, { useEffect, useState } from 'react';

import Slide from '../commons/Slide';
import BloqueImagen from '../components/BloqueImagen';
import Boton from '../commons/Boton';

import '../css/inicio.css';

import h1 from '../assets/home/1-carrusel.png';

import i1 from '../assets/home/1-imagen.png';
import i2 from '../assets/home/2-imagen.png';
import i3 from '../assets/home/3-imagen.png';
import i4 from '../assets/home/4-imagen.png';
import i5 from '../assets/home/5-imagen.png';
import i6 from '../assets/home/6-imagen.png';
import i7 from '../assets/home/7-imagen.png';
import i8 from '../assets/home/8-imagen.png';
import i9 from '../assets/home/9-imagen.png';

const Inicio = () => {
  const [slideIndex, setSlideIndex] = useState(1);

  useEffect(() => {
    let interval = setInterval(() => {
      plusSlide(1);
    }, 7000);

    return () => clearInterval(interval);
  });

  const plusSlide = (index) => {
    let current = slideIndex + index;
    let slides = document.querySelector('.cb-slider .cb-slides');
    let dots = document.querySelectorAll('.cb-slider .cb-dots span');

    if (current > 4) {
      setSlideIndex(1);
      current = 1;
    } else if (current < 1) {
      setSlideIndex(4);
      current = 4;
    } else {
      setSlideIndex(current);
    }

    slides.style.left = `-${current - 1}00%`;

    dots.forEach((item, i) => {
      item.classList.remove('dot-active');
      if (current - 1 === i) {
        item.classList.add('dot-active');
      }
    });
  };

  const currentSlider = (index) => {
    let slides = document.querySelector('.cb-slider .cb-slides');
    let slide = document.querySelectorAll('.cb-slider .cb-slide');
    let dots = document.querySelectorAll('.cb-slider .cb-dots span');
    if (index > slide.length) {
      setSlideIndex(1);
    } else if (index < 1) {
      setSlideIndex(slide.length);
    }

    dots.forEach((item, i) => {
      item.classList.remove('dot-active');
      if (index - 1 === i) {
        item.classList.add('dot-active');
      }
    });

    slides.style.left = `-${index - 1}00%`;
  };

  return (
    <>
      <div className="cb-slider">
        <div className="cb-slides">
          <Slide img={h1}>
            <div className="cb-info">
              <p className="image"></p>
              <span className="title">Financiando sostenibilidad</span>
              <p className="text">
                Conectamos inversionistas que quieren cambiar el mundo con
                empresas sostenibles.
              </p>
              <p className="text">Quiero:</p>
              <div className="actions">
                <Boton label="Invertir" onClick={() => {}} tabIndex={100} />
                <Boton
                  label="Financiarme"
                  filled={false}
                  onClick={() => {}}
                  tabIndex={101}
                />
              </div>
            </div>
          </Slide>
          <Slide img={h1}>
            <div className="cb-info">
              <Boton
                label="Empresario"
                filled={false}
                onClick={() => {}}
                tabIndex={104}
                style={{ marginBottom: '15px', borderRadius: '3px' }}
              />
              <span className="title">
                Financiamos <span>empresas sostenibles</span>
              </span>
              <p className="text">
                Te conectamos con el financiamiento necesario para hacer crecer
                tu empresa y el impacto de tus acciones en la sociedad.
              </p>
              <p className="text">
                Necesito un pr&eacute;stamo accesible a una tasa justa, quiero:
              </p>
              <div className="actions">
                <Boton label="Financiarme" onClick={() => {}} tabIndex={102} />
                <Boton
                  label="Descubrir m&aacute;s"
                  filled={false}
                  onClick={() => {}}
                  tabIndex={103}
                />
              </div>
            </div>
          </Slide>
          <Slide img={h1}>
            <div className="cb-info">
              <Boton
                label="Inversionista"
                filled={false}
                onClick={() => {}}
                tabIndex={105}
                style={{ marginBottom: '15px', borderRadius: '3px' }}
              />
              <span className="title">
                <span>Multiplicamos el impacto </span>de tu inversi&oacute;n
              </span>
              <p className="text">
                Hacemos que tu inversión genere ganancias para ti, y ayude a
                construir un mejor futuro para todos.
              </p>
              <p className="text">
                Deseo rentabilizar mis ahorros a tasas atractivas, quiero:
              </p>
              <div className="actions">
                <Boton label="Invertir" onClick={() => {}} tabIndex={106} />
              </div>
            </div>
          </Slide>
          <Slide img={h1}>
            <div className="cb-info">
              <p className="image"></p>
              <span className="title">Nuestro prop&oacute;sito</span>
              <p className="text">
                Transformar las finanzas en un motor de cambio que contribuya al
                desarrollo sostenible de nuestra sociedad.
              </p>
              <p className="text">Quiero:</p>
              <div className="actions">
                <Boton label="Invertir" onClick={() => {}} tabIndex={108} />
                <Boton
                  label="Financiarme"
                  filled={false}
                  onClick={() => {}}
                  tabIndex={109}
                />
              </div>
            </div>
          </Slide>
        </div>
        <div className="cb-arrow prev" onClick={() => plusSlide(-1)}></div>
        <div className="cb-arrow next" onClick={() => plusSlide(1)}></div>
        <div className="cb-dots">
          <span className="dot-active" onClick={() => currentSlider(1)}></span>
          <span onClick={() => currentSlider(2)}></span>
          <span onClick={() => currentSlider(3)}></span>
          <span onClick={() => currentSlider(4)}></span>
        </div>
      </div>
      <BloqueImagen clase="cb-inicio-four" imagen={i5}>
        <p className="image"></p>
        <label className="title">¿Qui&eacute;nes somos?</label>
        <p className="texto">
          Somos Finanser, un punto de encuentro entre inversionistas y empresas
          que desean generar un impacto positivo en el planeta y la sociedad.
        </p>
        <p className="texto">
          Empresa registrada en la Superintendencia de Banca y Seguros - SBS
          (Resoluci&oacute;n N° 02961-2021).
        </p>
      </BloqueImagen>
      <BloqueImagen clase="cb-inicio-three">
        <div className="bloque first">
          <div className="data">
            <p className="icono"></p>
            <label className="title">
              Conoce qu&eacute; es la sostenibilidad
            </label>
            <Boton
              label="M&aacute;s informaci&oacute;n"
              filled={false}
              onClick={() => {}}
              tabIndex={120}
            />
            <div className="icono-transparent"></div>
          </div>
          <div className="image">
            <img src={i1} title="Sostenibilidad"></img>
          </div>
        </div>
        <div className="bloque second">
          <div className="image">
            <img src={i2} title="Sostenible"></img>
          </div>
          <div className="data">
            <p className="icono"></p>
            <label className="title">Conoce si tu empresa es sostenible</label>
            <Boton
              label="Realizar test"
              filled={false}
              onClick={() => {}}
              tabIndex={121}
            />
            <div className="icono-transparent"></div>
          </div>
        </div>
      </BloqueImagen>
      <BloqueImagen
        clase="cb-inicio-three"
        subtitulo="Descubre nuestras soluciones"
      >
        <div className="bloque third">
          <div className="data">
            <p className="icono"></p>
            <label className="title">
              Soy una empresa sostenible y necesito un financiamiento
            </label>
            <Boton
              label="M&aacute;s informaci&oacute;n"
              onClick={() => {}}
              tabIndex={122}
            />
          </div>
          <div className="image">
            <img src={i3} title="Financiamiento"></img>
          </div>
        </div>
        <div className="bloque fourth">
          <div className="image">
            <img src={i4} title="Invertir"></img>
          </div>
          <div className="data">
            <p className="icono"></p>
            <label className="title">Quiero invertir y generar impacto</label>
            <Boton
              label="M&aacute;s informaci&oacute;n"
              onClick={() => {}}
              tabIndex={123}
            />
          </div>
        </div>
      </BloqueImagen>
      <BloqueImagen
        clase="cb-inicio-two"
        subtitulo="Aliados estrat&eacute;gicos"
      >
        <div
          className="cb-aliado"
          style={{ backgroundImage: `url('${i6}')` }}
        ></div>
        <div
          className="cb-aliado"
          style={{ backgroundImage: `url('${i7}')` }}
        ></div>
        <div
          className="cb-aliado"
          style={{ backgroundImage: `url('${i8}')` }}
        ></div>
        <div
          className="cb-aliado"
          style={{ backgroundImage: `url('${i9}')` }}
        ></div>
      </BloqueImagen>
    </>
  );
};

export default Inicio;
