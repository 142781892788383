import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Proptypes from 'prop-types';

import {
  getCaptchaAction,
  putCaptchaAction,
} from '../redux/actions/app.action';
import Texto from '../commons/Texto';

import RefreshIcon from '@material-ui/icons/Refresh';

const Captcha = ({ value, tabIndex, onChange, error }) => {
  const { captcha, captchaProcessing } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCaptchaAction());
  }, []);

  const handleRefreshCaptcha = () => {
    dispatch(putCaptchaAction(captcha.ct));
  };

  return (
    <>
      <div className="op-form-group cb-captcha">
        {captchaProcessing ? (
          <>
            <div></div>
            <i className="op-loading"></i>
          </>
        ) : (
          <>
            <img
              alt="captcha"
              src={`data:image/jpg;base64,${captcha.img}`}
              style={{ cursor: 'pointer' }}
            ></img>
            <RefreshIcon
              fontSize="large"
              onClick={handleRefreshCaptcha}
              style={{ cursor: 'pointer' }}
            />
          </>
        )}
      </div>
      {captchaProcessing === false && (
        <p>{`La imagen contiene ${captcha.si} caracteres`}</p>
      )}
      <Texto
        label="Escribe los caracteres"
        name="tx"
        value={value}
        tabIndex={tabIndex}
        onChange={(e) => onChange(e)}
        error={error}
      />
    </>
  );
};

Captcha.propTypes = {
  onChange: Proptypes.func.isRequired,
  tabIndex: Proptypes.number.isRequired,
  value: Proptypes.string,
  error: Proptypes.string,
};

export default Captcha;
