import React, { useEffect, useReducer } from 'react';

import { api } from '../redux/axios/api';
import { mensaje } from '../commons/Mensaje';

const FormularioContext = React.createContext();

export const useFormularioContext = () => {
  const context = React.useContext(FormularioContext);
  if (!context) {
    throw new Error(
      '!El componente compuesto no puede ser renderizado fuera de Tabla'
    );
  }
  return context;
};

export const useTablaPagination = () => {
  const {
    goNextPage,
    goPrevPage,
    goSelectedPage,
    activePageIndex,
    rows,
    total,
  } = useFormularioContext();
  return {
    goNextPage,
    goPrevPage,
    goSelectedPage,
    activePageIndex,
    rows,
    total,
  };
};

export const useTablaBody = () => {
  const { processing, data, setSelectedItem, selected } =
    useFormularioContext();
  return {
    processing,
    data,
    setSelectedItem,
    selected,
  };
};

export const useTablaSearch = () => {
  const { setSearch, search } = useFormularioContext();
  return {
    setSearch,
    search,
  };
};

export const useTablaFilter = () => {
  const { getData, params, dispatch, setSelectedItem } = useFormularioContext();
  return { getData, params, dispatch, setSelectedItem };
};

const defaultInitialState = {
  activePageIndex: 1,
  rows: 10,
  data: [],
  total: 0,
  processing: false,
  serviceName: null,
  search: null,
  viewContent: null,
  params: {},
  errors: null,
  selected: null,
  openModal: false,
};

export const actions = {
  NEXT_PAGE: 'NEXT_PAGE',
  PREV_PAGE: 'PREV_PAGE',
  SELECTED_PAGE: 'SELECTED_PAGE',
  SET_ROWS: 'SET_ROWS',
  SET_SERVICE: 'SET_SERVICE',
  GET_DATA: 'GET_DATA',
  SET_PROCESS: 'SET_PROCESS',
  SET_SEARCH: 'SET_SEARCH',
  SELECTED_ITEM: 'SELECTED_ITEM',
  TRANSACTION_FAIL: 'TRANSACTION_FAIL',
  TRANSACTION_CANCEL: 'TRANSACTION_CANCEL',
};

const combineReducer =
  (...reducers) =>
  (state, action) => {
    return reducers.reduce((acc, nextReducer) => {
      return nextReducer(acc, action);
    }, state);
  };

const formularioReducer = (state, action) => {
  switch (action.type) {
    case actions.NEXT_PAGE:
      return { ...state, activePageIndex: action.payload };
    case actions.PREV_PAGE:
      return { ...state, activePageIndex: action.payload };
    case actions.SELECTED_PAGE:
      return { ...state, activePageIndex: action.payload };
    case actions.SET_ROWS:
      return { ...state, rows: action.payload };
    case actions.SET_SERVICE:
      return { ...state, serviceName: action.payload };
    case actions.SET_PROCESS:
      return { ...state, processing: action.payload };
    case actions.SET_SEARCH:
      return { ...state, search: action.payload, activePageIndex: 1 };
    case actions.GET_DATA:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.count,
      };
    case actions.SELECTED_ITEM:
      return {
        ...state,
        selected: action.payload,
        openModal: true,
      };
    case actions.TRANSACTION_CANCEL:
      return {
        ...state,
        selected: action.payload,
        errors: action.payload,
        openModal: false,
      };
    case actions.TRANSACTION_FAIL:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};

const defaultReducer = (state, action) => state;

const Formulario = ({
  children,
  reducer = defaultReducer,
  initialState = {},
}) => {
  const [state, dispatch] = useReducer(
    combineReducer(formularioReducer, reducer),
    {
      ...defaultInitialState,
      ...initialState,
    }
  );

  useEffect(() => {
    getData();
  }, [state.activePageIndex, state.search]);

  const goNextPage = () => {
    let p = Math.trunc(state.activePageIndex / state.rows) + 1;
    dispatch({ type: actions.NEXT_PAGE, payload: p * state.rows + 1 });
  };

  const goPrevPage = () => {
    let p = Math.trunc(state.activePageIndex / state.rows) - 1;
    dispatch({ type: actions.PREV_PAGE, payload: p * state.rows + 1 });
  };

  const goSelectedPage = (n) => {
    dispatch({ type: actions.SELECTED_PAGE, payload: n + 1 });
  };

  const setRows = (n) => {
    dispatch({ type: actions.SET_ROWS, payload: n });
  };

  const setSearch = (n) => {
    dispatch({ type: actions.SET_SEARCH, payload: n });
  };

  const getData = () => {
    if (state.serviceName) {
      let params = {
        ...state.params,
        search: state.search,
        page: state.activePageIndex - 1,
        pageSize: state.rows,
      };
      dispatch({ type: actions.SET_PROCESS, payload: true });
      api
        .get(state.serviceName, { params })
        .then((response) =>
          dispatch({ type: actions.GET_DATA, payload: response })
        )
        .catch(() =>
          dispatch({ type: actions.GET_DATA, payload: { data: [], count: 0 } })
        )
        .finally(() => dispatch({ type: actions.SET_PROCESS, payload: false }));
    }
  };

  const setProcess = (n) => {
    dispatch({ type: actions.SET_PROCESS, payload: n });
  };

  const setSelectedItem = (n) => {
    dispatch({ type: actions.SELECTED_ITEM, payload: n });
  };

  const callTransaction = (request) => {
    dispatch({ type: actions.SET_PROCESS, payload: true });
    api(request)
      .then(() => {
        mensaje('Operación Satisfactoria');
        getData();
        if (request.successfulAction) {
          dispatch(request.successfulAction);
        } else {
          dispatch({
            type: actions.TRANSACTION_CANCEL,
            payload: request.model,
          });
        }
      })
      .catch((error) => {
        if (error.data.errors) {
          dispatch({
            type: actions.TRANSACTION_FAIL,
            payload: error.data.errors,
          });
        }
        dispatch({ type: actions.SET_PROCESS, payload: false });
      });
  };

  const cancelTransaction = (n) => {
    dispatch({ type: actions.TRANSACTION_CANCEL, payload: n });
  };

  const context = {
    ...state,
    dispatch,
    goNextPage,
    goPrevPage,
    goSelectedPage,
    setRows,
    setProcess,
    setSearch,
    setSelectedItem,
    getData,
    callTransaction,
    cancelTransaction,
  };

  return (
    <FormularioContext.Provider value={context}>
      <div
        className={`cb-component ${state.viewContent ? state.viewContent : ''}`}
      >
        {children}
      </div>
    </FormularioContext.Provider>
  );
};

export default Formulario;
