import React from 'react';

const BloqueImagen = (props) => {
  return (
    <div
      className={`cb-global-bloque ${props.clase ? props.clase : ''} ${
        props.fondo ? 'fondo' : ''
      }${props.alterno ? ' alterno' : ''}`}
    >
      <div className="titulo">
        <span className="subtitle">{props.subtitulo}</span>
        <span className="title">{props.titulo}</span>
        <span className="tema">{props.tema}</span>
      </div>
      <div
        className={`content${
          props.imagen && !(props.imagen && props.fondo) ? '' : ' total'
        }`}
      >
        {props.children}
      </div>
      <div className="overlay"></div>
      {props.imagen && (
        <div
          className={`${props.imagen_class ? props.imagen_class : 'imagen'}`}
          style={{ backgroundImage: `url('${props.imagen}')` }}
        ></div>
      )}
    </div>
  );
};

export default BloqueImagen;
