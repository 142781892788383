import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DashboardIcon from '@material-ui/icons/Dashboard';

const Authenticate = ({ loggedIn }) => {
  return (
    <div className="cb-header-actions">
      {loggedIn ? (
        <>
          <NavLink
            to={'/dashboard'}
            style={{
              textDecoration: 'none',
              color: '#0062ff',
              display: 'flex',
            }}
          >
            <DashboardIcon fontSize="large" />
          </NavLink>
          <AccountCircleIcon
            fontSize="large"
            style={{ cursor: 'pointer', color: '#0062ff' }}
          />
        </>
      ) : (
        <>
          <NavLink
            className="cb-boton filled"
            to={'/register'}
            style={{
              textDecoration: 'none',
            }}
          >
            <AccountCircleIcon style={{ fontSize: '17px' }} /> Reg&iacute;strate
          </NavLink>
          {/* <NavLink
            className="cb-boton cb-dinamic"
            to={'/login'}
            style={{
              '--button-border-color': '#0062ff',
              color: '#0062ff',
              padding: '10px 20px',
              textDecoration: 'none',
            }}
          >
            Iniciar sesi&oacute;n
          </NavLink> */}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.app.loggedIn,
  };
};

export default connect(mapStateToProps)(Authenticate);
