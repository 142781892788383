import JSEncrypt from 'jsencrypt';

/**
 * Formatear date a string.
 * @param {date|number|string} fecha fecha a formatear
 * @param {string} texto respuesta si no hay fecha
 * @param {boolean} time mostrar tiempo (default = true)
 */
export const formatoFecha = (fecha, texto, time = true) => {
  if (fecha === null) return texto ? texto : 'Indefinido';
  if (fecha === '') return texto ? texto : 'Indefinido';
  var date = new Date(fecha);
  var day = date.getDate();
  var month = date.getMonth() + 1;
  var year = date.getFullYear();

  var hour = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();

  if (time) {
    return (
      (day < 10 ? '0' + day : day) +
      '/' +
      (month < 10 ? '0' + month : month) +
      '/' +
      year +
      ' ' +
      (hour < 10 ? '0' + hour : hour) +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes) +
      ':' +
      (seconds < 10 ? '0' + seconds : seconds)
    );
  } else {
    return (
      (day < 10 ? '0' + day : day) +
      '/' +
      (month < 10 ? '0' + month : month) +
      '/' +
      year
    );
  }
};

/**
 * Compara si la fecha1 es menor o igual que le fecha2.
 * @param {number | string | date} fecha1
 * @param {number | string | date} fecha2
 */
export const compararFechas = (fecha1, fecha2) => {
  if (fecha1 === null && fecha2 === null) {
    return false;
  }
  if (fecha1 === null) {
    return false;
  }
  let date1 = new Date(fecha1);
  let date2 = fecha2 ? new Date(fecha2) : new Date();

  return +date1 <= +date2;
};

export const encriptado = (data) => {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(process.env.AUDITOR);
  return encrypt.encrypt(data);
};

/**
 * Formater un valor monetario con comas y decimales.
 * @param {decimal} val valor decimal
 */
export const formatoMonetario = (val) => {
  let negativo = false;
  let v = '';
  val = val + '';
  if (val.charAt(0) == '-') {
    for (var i = 1; i < val.length; i++) {
      v += val.charAt(i);
    }
    val = v;
    negativo = true;
  }
  let cadena = val.split('.');
  let num = cadena[0];
  let fmto = '';
  let ind = 1;
  for (i = num.length - 1; i >= 0; i--) {
    fmto = num.charAt(i) + fmto;
    if (ind % 3 == 0 && ind != 3 && ind != num.length)
      //para poner las '
      fmto = ',' + fmto;
    else if (ind == 3 && ind != num.length)
      //para poner las ,
      fmto = ',' + fmto;
    ind++;
  }

  var signo = negativo == true ? '-' : '';
  let decimal = cadena[1];
  if (typeof decimal != 'undefined') {
    //si no es undefined
    if (decimal.toString().length === 1) {
      decimal = decimal + '0';
    }
    return `${signo + fmto}.${decimal}`;
  } else return signo + fmto + '.00';
};

export const redondeoDecimales = (monto, decimales = 2) => {
  monto = quitarFormatoMonetario(monto);
  return +(Math.round(monto + 'e+' + decimales) + 'e-' + decimales);
};

/**
 * Quitar el formato #'###,###
 * @param {string} val valor monetario formateado
 */
export const quitarFormatoMonetario = (val) => {
  val = val + '';
  var cadena1 = val.split(',');
  if (cadena1.length == 1)
    if (isNaN(val))
      //No tiene ,
      return parseFloat('0.00');
    else {
      if (val != '') {
        return parseFloat(val);
      } else {
        return parseFloat('0.00');
      }
    }
  else {
    var fmto = '';
    var cadena2 = cadena1[0].split(',');
    if (cadena2.length == 1)
      //No tiene '
      for (var i = 0; i < cadena1.length; i++) fmto += cadena1[i];
    else {
      for (i = 0; i < cadena2.length; i++) fmto += cadena2[i];

      fmto += cadena1[1];
    }

    if (isNaN(fmto)) return parseFloat('0.00');
    else return parseFloat(fmto);
  }
};

export const caracteresUnicode = () => {
  let str = '';
  str += '\u00e1'; //a con tilde
  str += '\u00e9'; //e con tilde
  str += '\u00ed'; //i con tilde
  str += '\u00f3'; //o con tilde
  str += '\u00fa'; //u con tilde
  str += '\u00c1'; //A con tilde
  str += '\u00c9'; //E con tilde
  str += '\u00cd'; //I con tilde
  str += '\u00d3'; //O con tilde
  str += '\u00da'; //U con tilde
  str += '\u00f1'; //enie
  str += '\u00d1'; //ENIE
  str += '\u0040'; //Arroba
  return str;
};
