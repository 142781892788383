import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';

import { useTablaFilter, useTablaSearch } from '../../commons/Formulario';
import { TYPE_FILTER } from '../../redux/types/app.types';
import { caracteresUnicode } from '../../commons/Utilitarios';

const BlogFilter = () => {
  const { categorias, secciones } = useSelector((state) => state.detalle);
  const { dispatch, getData, params } = useTablaFilter();
  const { setSearch, search } = useTablaSearch();
  const [textSearch, setTextSearch] = useState(search);

  useEffect(() => {
    getData();
  }, [params]);

  const handlePressSearch = (e) => {
    let re = new RegExp(`[a-zA-Z0-9-\_${caracteresUnicode()}]`);
    if (e.key === 'Enter') {
      setSearch(textSearch);
    }
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div className="cb-blog-filter">
      <div className="cb-blog-bloques">
        <h3>B&uacute;squeda</h3>
        <div className="search">
          <input
            type="text"
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
            onKeyPress={handlePressSearch}
            onPaste={(e) => e.preventDefault()}
          />
          <SearchIcon
            style={{ cursor: 'pointer' }}
            onClick={() => setSearch(textSearch)}
          />
        </div>
      </div>
      <div className="cb-blog-bloques">
        <h3>Categor&iacute;as</h3>
        <ul>
          {categorias?.map((x) => (
            <li
              key={x.ma}
              className={`${params.cc === x.ma ? 'activo' : ''}`}
              onClick={() =>
                dispatch({
                  type: TYPE_FILTER,
                  payload: { cc: x.ma, cs: params.cs },
                })
              }
            >
              {x.ds}
            </li>
          ))}
          <li
            onClick={() =>
              dispatch({
                type: TYPE_FILTER,
                payload: { cc: null, cs: params.cs },
              })
            }
          >
            Todas
          </li>
        </ul>
      </div>
      <div className="cb-blog-bloques">
        <h3>Secciones</h3>
        <ul>
          {secciones?.map((x) => (
            <li
              key={x.ma}
              className={`${params.cs === x.ma ? 'activo' : ''}`}
              onClick={() =>
                dispatch({
                  type: TYPE_FILTER,
                  payload: { cs: x.ma, cc: params.cc },
                })
              }
            >
              {x.ds}
            </li>
          ))}
          <li
            onClick={() =>
              dispatch({
                type: TYPE_FILTER,
                payload: { cs: null, cc: params.cc },
              })
            }
          >
            Todas
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BlogFilter;
