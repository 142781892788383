import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Texto from '../commons/Texto';
import Boton from '../commons/Boton';
import Icon from '../commons/Icon';
import Captcha from '../components/Captcha';

import { postLoginAction } from '../redux/actions/app.action';

import Open from '../assets/commons/open.svg';
import Close from '../assets/commons/close.svg';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import '../css/pages/login.css';

const Login = () => {
  const [{ us, pd, tx }, setUser] = useState({ us: null, pd: null, tx: null });
  const [openCurrent, setOpenCurrent] = useState(false);
  const dispatch = useDispatch();
  const { error, processing, loggedIn } = useSelector((state) => state.app);

  const handleChange = (item) => {
    const { name, value } = item;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLogin = () => {
    dispatch(postLoginAction({ us, pd, tx }));
    setUser((prev) => ({
      ...prev,
      tx: '',
    }));
  };

  return (
    <div className="cb-content-login">
      <div className="cb-bloque cb-fondo">
        <label>Todos los derechos reservados 2021. Finanser.</label>
      </div>
      <div className="cb-bloque cb-login">
        <div className="content" style={{ paddingTop: '10px' }}>
          <div className="logo"></div>
          <p className="icono"></p>
          <label className="title">Iniciar sesi&oacute;n</label>
          <span className="sub-title">Ingresa a tu cuenta Finanser</span>
          <Texto
            name="us"
            value={us}
            label="Correo electr&oacute;nico"
            tabIndex={1}
            onChange={handleChange}
            autoFocus
          />
          <Texto
            name="pd"
            value={pd}
            label="Contrase&ntilde;a"
            tabIndex={2}
            type={`${openCurrent ? 'text' : 'password'}`}
            onChange={(e) => handleChange(e)}
            action={() =>
              openCurrent ? (
                <Icon
                  svg={Open}
                  transparent
                  size={24}
                  width={45}
                  onClick={() => setOpenCurrent(false)}
                />
              ) : (
                <Icon
                  svg={Close}
                  transparent
                  size={24}
                  width={45}
                  onClick={() => setOpenCurrent(true)}
                />
              )
            }
            restrict="password"
          />
          <Captcha tabIndex={3} value={tx} onChange={handleChange} />
          <NavLink
            to="/forget"
            className="texto"
            style={{ alignSelf: 'flex-end' }}
          >
            ¿Olvidaste tu contrase&ntilde;a?
          </NavLink>
          <Boton
            label="Ingresar"
            onClick={handleLogin}
            tabIndex={4}
            disabled={processing}
          />
          {error && <label className="cb-error">{error}</label>}
          <span className="texto" style={{ alignSelf: 'center' }}>
            ¿No tienes una cuenta?{'  '}
            <NavLink to={'/register'}>Reg&iacute;strate</NavLink>
          </span>
          <NavLink to="/" className="texto">
            <ChevronLeftIcon /> Ir inicio
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Login;
