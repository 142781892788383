import React from 'react';

import imagenOff from '../assets/commons/image.svg';
import Icon from './Icon';

const ImagenOff = () => {
  return <Icon svg={imagenOff} size={80} transparent />;
};

export default ImagenOff;
