import { constantes } from './constantes';

export const servicios = {
  LOGIN: `${constantes.MS_AUTENTICACION}/v1/autentificacion/login`,
  CAPTCHA: `${constantes.MS_AUTENTICACION}/v1/captchas`,
  CLIENTE: `${constantes.MS_AUTENTICACION}/v1/clientes`,
  BLOG: `${constantes.MS_ZERO}/v1/blogs`,
  ARCHIVO: `${constantes.MS_ZERO}/v1/archivos`,
  DETALLES: `${constantes.MS_ZERO}/v1/maestros`,
};
