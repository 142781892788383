import axios from 'axios';
import Swal from 'sweetalert2';

import { logoutAction } from '../actions/app.action';
import { encriptado } from '../../commons/Utilitarios';

let instance = axios.create();

instance.interceptors.request.use(
  (config) => {
    let current = localStorage.getItem('currentUser');
    if (current) {
      current = JSON.parse(current);
      config.headers['box-auth'] = current.tk;
      let audit = {
        em: current.ie,
        su: current.is,
        us: current.us,
      };
      config.headers['box-au'] = JSON.stringify(audit);
    }
    let dato = new Date().getTime();
    config.headers['box-rr'] = encriptado(dato.toString());
    config.headers['Content-type'] = 'application/json';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (!error.response) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: 'error',
        title: 'Error en el servicio',
      });

      return Promise.reject({ data: 'Error' });
    }

    if (
      error.response.status === 400 &&
      error.response.data.errorCode === 1008
    ) {
      Swal.fire({
        title: '',
        text: error.response.data.detail,
        icon: 'warning',
        confirmButtonColor: 'orange',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          logoutAction();
        }
      });
    } else if (error.response.data.detail) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: error.response.status === 500 ? 'error' : 'warning',
        title: error.response.data.detail,
      });
    }

    return Promise.reject(error.response);
  }
);

export const api = instance;
