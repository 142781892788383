import * as fromDetalle from '../actions/detalle.action';

let initialState = {
  categorias: [],
  secciones: [],
};

export const detalleReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case fromDetalle.DETALLE_LIST:
      switch (payload.co) {
        case 'CATEGORIA':
          return {
            ...state,
            categorias: payload.data,
          };
        case 'SEGMENTO':
          return {
            ...state,
            secciones: payload.data,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};
