import React, { useState } from 'react';
import Proptypes from 'prop-types';

import '../css/commons/boton.css';

const Boton = ({
  className,
  type,
  disabled,
  tabIndex,
  icon,
  label,
  onClick,
  filled,
  ...rest
}) => {
  return (
    <button
      className={`cb-boton${className ? ' ' + className : ''} ${
        filled ? 'filled' : ''
      }`}
      onClick={() => {
        onClick();
      }}
      type={type}
      disabled={disabled}
      tabIndex={tabIndex}
      {...rest}
    >
      {disabled && <i className="cb-loading-mini"></i>}
      {icon && icon()}
      {` ${label}`}
    </button>
  );
};

Boton.propTypes = {
  label: Proptypes.string.isRequired,
  type: Proptypes.string,
  style: Proptypes.object,
  disabled: Proptypes.bool,
  className: Proptypes.string,
  onClick: Proptypes.func,
  tabIndex: Proptypes.number,
  icon: Proptypes.func,
  filled: Proptypes.bool,
};

Boton.defaultProps = {
  type: 'button',
  disabled: false,
  filled: true,
};

export default Boton;
