import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';

import Header from './components/Header';
import Footer from './components/Footer';
import PrivateRoute from './components/PrivateRoute';

import Inicio from './pages/Inicio';
import Nosotros from './pages/Nosotros';
import Blog from './pages/blog/Blog';
import Contacto from './pages/Contacto';
import Login from './pages/Login';
import Olvido from './pages/Olvido';
import Registro from './pages/Registro';
import Cambio from './pages/Cambio';
import Dashboard from './pages/Dashboard';

import './css/general.css';
import './css/titulo.css';
import './css/bloqueimagen.css';
import './css/tipo.css';
import './css/icono.css';
import './css/iconotext.css';
import './css/perfil.css';

const App = ({ loggedIn }) => {
  return (
    <Router>
      <Switch>
        <Route
          path="/login"
          render={() => (loggedIn ? <Redirect to={'/'} /> : <Login />)}
        ></Route>
        <Route path="/forget" component={Olvido}></Route>
        <Route path="/register" component={Registro}></Route>
        <Route
          path="/:fi/cambio-contrasenia-finanser/:sc"
          component={Cambio}
        ></Route>
        <PrivateRoute path="/dashboard" component={Dashboard}></PrivateRoute>
        <Route path="/nosotros">
          <>
            <Header />
            <div className="cb-body">
              <div className="cb-body-contenedor">
                <Nosotros />
              </div>
            </div>
            <Footer />
          </>
        </Route>
        <Route path="/blog">
          <>
            <Header />
            <div className="cb-body">
              <div className="cb-body-contenedor">
                <Blog />
              </div>
            </div>
            <Footer />
          </>
        </Route>
        <Route path="/contacto">
          <>
            <Header />
            <div className="cb-body">
              <div className="cb-body-contenedor">
                <Contacto />
              </div>
            </div>
            <Footer />
          </>
        </Route>
        <Route exact path="/">
          <>
            <Header />
            <div className="cb-body">
              <div className="cb-body-contenedor">
                <Inicio />
              </div>
            </div>
            <Footer />
          </>
        </Route>
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.app.loggedIn,
  };
};

export default connect(mapStateToProps)(App);
