import React, { useState } from 'react';

import Titulo from '../components/Titulo';
import Texto from '../commons/Texto';
import Combo from '../commons/Combo';
import Boton from '../commons/Boton';
import Icon from '../commons/Icon';

import i1 from '../assets/contacto/1-imagen.png';
import c1 from '../assets/contacto/i-telephone.svg';
import c2 from '../assets/contacto/i-at.svg';
import c3 from '../assets/contacto/i-avatar.svg';
import c4 from '../assets/contacto/i-comment.svg';
import BloqueImagen from '../components/BloqueImagen';

import '../css/contacto.css';

const Contacto = () => {
  const [solicitud, setSolicitud] = useState({
    nm: null,
    ap: null,
    ci: null,
    co: null,
    tl: null,
    tp: null,
    cm: null,
  });

  const [checked, setChecked] = useState(false);

  const handleChange = (item) => {
    const { name, value } = item;
    setSolicitud((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <>
      <Titulo titulo="Contacto" imagen={i1} />
      <BloqueImagen clase="cb-inicio-five">
        <div className="bloque">
          <div className="image"></div>
          <label className="title">Informaci&oacute;n de contacto</label>
          <p className="texto">
            is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard dummy text ever since the
            1500s.
          </p>
          <ul className="datos">
            <li>contacto@finanser.com</li>
            <li>999 888 777</li>
            <li>Lima, Perú</li>
          </ul>
        </div>
        <div className="bloque">
          <label className="title">¿Alguna duda?</label>
          <div className="op-form">
            <Texto
              name="nm"
              value={solicitud.nm}
              label={'Nombres'}
              tabIndex={10}
              onChange={handleChange}
              size={2}
              autoFocus
              icon={() => <Icon svg={c3} transparent />}
            />
            <Texto
              name="ap"
              value={solicitud.ap}
              label={'Apellidos'}
              tabIndex={11}
              onChange={handleChange}
              size={2}
              icon={() => <Icon svg={c3} transparent />}
            />
            <Combo
              name="ci"
              value={solicitud.ci}
              label="Ciudad"
              size={2}
              tabIndex={12}
              data={[{ value: '01', label: 'Lima' }]}
              onChange={handleChange}
            />
            <Texto
              name="co"
              value={solicitud.co}
              label="Correo electr&oacute;nico"
              tabIndex={13}
              onChange={handleChange}
              size={2}
              icon={() => <Icon svg={c2} transparent />}
            />
            <Texto
              name="tl"
              value={solicitud.tl}
              label="Tel&eacute;fono"
              tabIndex={14}
              onChange={handleChange}
              size={2}
              restrict={'int'}
              icon={() => <Icon svg={c1} transparent />}
            />
            <Combo
              name="tp"
              value={solicitud.tp}
              label="Tipo"
              size={2}
              tabIndex={15}
              data={[
                { value: '01', label: 'Empresario' },
                { value: '02', label: 'Inversor' },
              ]}
              onChange={handleChange}
              icon={() => <Icon svg={c3} transparent />}
            />
            <Texto
              name="cm"
              value={solicitud.cm}
              label="Comentario"
              tabIndex={16}
              onChange={handleChange}
              icon={() => <Icon svg={c4} transparent />}
            />
            <div className="op-form-group op-col-1 checkbox">
              <input
                id="txtChecbox"
                type="checkbox"
                defaultValue={checked}
                onChange={() => setChecked(!checked)}
              />
              <label htmlFor="txtChecbox">
                He le&iacute;do y estoy de acuerdo con las pol&iacute;ticas de
                privacidad
              </label>
            </div>
            <Boton label="Enviar" onClick={() => {}} />
          </div>
        </div>
      </BloqueImagen>
    </>
  );
};

export default Contacto;
