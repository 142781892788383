import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

import Texto from '../commons/Texto';
import Boton from '../commons/Boton';
import Icon from '../commons/Icon';
import Captcha from '../components/Captcha';

import Open from '../assets/commons/open.svg';
import Close from '../assets/commons/close.svg';

import { patchRestablecerAction } from '../redux/actions/cliente.action';

import '../css/pages/login.css';

const Cambio = () => {
  const [{ us, nu, cn, cv, tx }, setUser] = useState({
    us: null,
    nu: null,
    cn: null,
    cv: null,
    tx: null,
  });
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.app);
  const { errors, processing } = useSelector((state) => state.cliente);
  const [openCurrent, setOpenCurrent] = useState(false);
  const [openCurrentConfirm, setOpenCurrentConfirm] = useState(false);
  const { fi } = useParams();

  useEffect(() => {
    handleChange({ name: 'us', value: fi });
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRestablecer = () => {
    dispatch(patchRestablecerAction({ us, nu, cn, cv, tx }));
    setUser((prev) => ({
      ...prev,
      tx: '',
    }));
  };

  return (
    <div className="cb-content-login">
      <div className="cb-bloque cb-fondo">
        <label>Todos los derechos reservados 2021. Finanser.</label>
      </div>
      <div className="cb-bloque cb-login">
        <div className="content">
          <div className="logo"></div>
          <p className="icono"></p>
          <label className="title">Cambiar contrase&ntilde;a</label>
          <span className="sub-title">Ingresa una nueva contrase&ntilde;a</span>
          <Texto
            name="nu"
            value={nu}
            label="Contrase&ntilde;a nueva"
            tabIndex={1}
            type={`${openCurrent ? 'text' : 'password'}`}
            onChange={handleChange}
            error={errors.nu}
            action={() =>
              openCurrent ? (
                <Icon
                  svg={Open}
                  transparent
                  size={24}
                  width={45}
                  onClick={() => setOpenCurrent(false)}
                />
              ) : (
                <Icon
                  svg={Close}
                  transparent
                  size={24}
                  width={45}
                  onClick={() => setOpenCurrent(true)}
                />
              )
            }
            restrict="password"
            autoFocus
          />
          <Texto
            name="cn"
            value={cn}
            label="Confirmar contrase&ntilde;a nueva"
            tabIndex={2}
            type={`${openCurrentConfirm ? 'text' : 'password'}`}
            onChange={handleChange}
            error={errors.cn}
            action={() =>
              openCurrentConfirm ? (
                <Icon
                  svg={Open}
                  transparent
                  size={24}
                  width={45}
                  onClick={() => setOpenCurrentConfirm(false)}
                />
              ) : (
                <Icon
                  svg={Close}
                  transparent
                  size={24}
                  width={45}
                  onClick={() => setOpenCurrentConfirm(true)}
                />
              )
            }
            restrict="password"
          />
          <Texto
            name="cv"
            value={cv}
            label="C&oacute;digo de verificaci&oacute;n"
            tabIndex={3}
            error={errors.cv}
            onChange={handleChange}
            enabledPaste
          />
          <Captcha value={tx} onChange={handleChange} tabIndex={4} />
          {error && <label className="cb-error">{error}</label>}
          <Boton
            label="Cambiar"
            onClick={handleRestablecer}
            tabIndex={5}
            disabled={processing}
          />
          <NavLink
            className="cb-boton cb-dinamic"
            to={'/login'}
            style={{
              '--button-border-color': '#0062ff',
              color: '#0062ff',
              padding: '10px 20px',
              textDecoration: 'none',
              marginTop: '10px',
            }}
          >
            Iniciar sesi&oacute;n
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Cambio;
