import React from 'react';

const Perfil = (props) => {
	return (
		<div className="cb-global-perfil">
			<div
				className="avatar"
				style={{ backgroundImage: `url('${props.img}')` }}
			></div>
			<label>{props.nombre}</label>
			<span>{props.puesto}</span>
			<div className="separador"></div>
			<p>{props.descripcion}</p>
			<div className="redes">
				<span className="facebook"></span>
			</div>
		</div>
	);
};

export default Perfil;
