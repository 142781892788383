import React, { useState } from 'react';

import { confirmacion, mensaje, mensajeMini } from '../commons/Mensaje';
import { caracteresUnicode } from '../commons/Utilitarios';

import '../css/footer.css';
import { api } from '../redux/axios/api';
import { servicios } from '../redux/helpers';

const Footer = () => {
  const [solicitud, setSolicitud] = useState({
    no: '',
    uc: '',
    nt: '',
    tp: '',
    ms: '',
  });
  const [processing, setProcessing] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSolicitud((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleKeyPress = (e) => {
    let re = new RegExp(`[a-zA-Z0-9-/.:+,;\_\\s${caracteresUnicode()}]`);
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleSend = () => {
    if (checked) {
      setProcessing(true);
      api
        .post(`${servicios.CLIENTE}/contacto`, solicitud)
        .then(() => {
          mensaje('Correo enviado');
          setSolicitud({
            no: '',
            uc: '',
            nt: '',
            tp: '',
            ms: '',
          });
          setChecked(false);
        })
        .catch((error) => {
          let message = error.data?.detail;
          if (error.data?.errors) {
            let items = Object.entries(error.data?.errors)[0];
            let key = '';
            switch (items[0]) {
              case 'no':
                key = 'Nombres';
                break;
              case 'uc':
                key = 'Correo';
                break;
              case 'nt':
                key = items[1].includes('teléf') ? '' : 'Teléfono';
                break;
              case 'tp':
                key = 'Tipo';
                break;
              case 'ms':
                key = 'Mensaje';
                break;
              default:
                break;
            }
            message = `${key} ${items[1]}`;
          }
          mensajeMini(message);
        })
        .finally(() => setProcessing(false));
    } else {
      mensajeMini(
        'Debe aceptar las condiciones de tratamiento de datos',
        'warning'
      );
    }
  };

  return (
    <div className="cb-footer">
      <div className="cb-footer-contendor">
        <div className="cb-logo"></div>
        <div className="cb-redes">
          <a href="https://www.facebook.com/Finanser.pe" target="_blank">
            <i className="fa fa-facebook fa-lg" aria-hidden="true"></i>
          </a>
          <a href="https://www.instagram.com/finanserperu/" target="_blank">
            <i className="fa fa-instagram fa-lg" aria-hidden="true"></i>
          </a>
        </div>
        <div className="cb-phone">
          <span>
            <i className="fa fa-mobile fa-lg" aria-hidden="true"></i>
          </span>
          <div>¿Tiene alguna pregunta?</div>
          <div>(+51) 941 361 507</div>
        </div>
        <div className="cb-email">
          <span>
            <i className="fa fa-envelope fa-lg" aria-hidden="true"></i>
          </span>
          <div>Env&iacute;anos un correo</div>
          <div>contacto@finanser.com.pe</div>
        </div>
        <div className="cb-address">
          <span>
            <i className="fa fa-map-marker fa-lg" aria-hidden="true"></i>
          </span>
          <div>
            Calle Germ&aacute;n Schreiber 276, Oficina 240, Urb. Santa Ana, San
            Isidro, Lima - Perú
          </div>
        </div>
        <div className="cb-form">
          <input
            name="no"
            value={solicitud.no}
            placeholder="Nombres y apellidos"
            tabIndex={200}
            onChange={handleChange}
            onKeyPress={(e) => handleKeyPress(e)}
            onPaste={(e) => (enabledPaste ? {} : e.preventDefault())}
          />
          <input
            name="uc"
            value={solicitud.uc}
            placeholder="Correo"
            tabIndex={201}
            onChange={handleChange}
            onKeyPress={(e) => handleKeyPress(e)}
            onPaste={(e) => (enabledPaste ? {} : e.preventDefault())}
          />
          <input
            name="nt"
            value={solicitud.nt}
            placeholder="Tel&eacute;fono"
            tabIndex={202}
            onChange={handleChange}
            onKeyPress={(e) => handleKeyPress(e)}
            onPaste={(e) => (enabledPaste ? {} : e.preventDefault())}
          />
          <select
            name="tp"
            value={solicitud.tp}
            tabIndex={204}
            onChange={handleChange}
          >
            <option hidden>Emprendedor / Inversionista</option>
            <option value={'01'}>Emprendedor</option>
            <option value={'02'}>Inversionista</option>
          </select>
          <textarea
            name="ms"
            value={solicitud.ms}
            placeholder="Mensaje"
            tabIndex={205}
            onChange={handleChange}
            onKeyPress={(e) => handleKeyPress(e)}
            onPaste={(e) => (enabledPaste ? {} : e.preventDefault())}
          />
          {processing ? (
            <div className="loader"></div>
          ) : (
            <button
              type="button"
              tabIndex={206}
              onClick={() =>
                confirmacion('¿Está seguro?', 'De enviar la información')
                  .then(() => handleSend())
                  .catch(() => {})
              }
            >
              Enviar
            </button>
          )}
          <div>
            <input
              id="chkConfirm"
              type="checkbox"
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
            <label htmlFor="chkConfirm">
              He le&iacute;do las condiciones de tratamiento de mis datos
              personales y autorizo de forma expresa su uso
            </label>
          </div>
        </div>
        <div className="cb-legal">
          <div></div>
          <label>AVISO LEGAL</label>
          <p>
            Finanser administra una plataforma de pr&eacute;stamos P2P, a
            trav&eacute;s de la cual un inversionista registrado otorga un
            cr&eacute;dito a otro usuario registrado cuya empresa a la cual
            representa cumple con alguna caracter&iacute;stica de
            sostenibilidad, o que, en todo caso, desee introducirse en ese
            camino. Asimismo, Finanser se encuentra inscrita como empresa de
            pr&eacute;stamos en el “Registro de Empresas y Personas que
            efect&uacute;an Operaciones Financieras o de Cambio de Moneda” de la
            Superintendencia de Banca, Seguros y AFP (SBS). Finanser no realiza
            actividad de financiamiento participativo financiero (crowdfunding)
            regulado en el T&iacute;tulo IV del Decreto de Urgencia N° 013-2020
            y el Reglamento de la Actividad de Financiamiento Participativo
            Financiero y sus Sociedades Administradoras, aprobado por
            Resoluci&oacute;n N° 045-2021-SMV/02. Finanser cuenta con controles
            en materia de prevenci&oacute;n del lavado de activos y
            financiamiento del terrorismo, cumpliendo con los est&aacute;ndares
            de la industria y como sujeto obligado a reportar a la Unidad de
            Inteligencia Financiera (UIF). Finanser no recibe dep&oacute;sitos
            ni realiza intermediaci&oacute;n financiera. Los fondos de los
            prestamistas y/o prestatarios no pasan en ning&uacute;n momento por
            cuentas de titularidad de Finanser o sus representantes. Finanser no
            representa a los prestamistas y/o prestatarios, quienes
            voluntariamente se registran en la plataforma y deciden realizar
            operaciones crediticias de acuerdo con sus intereses particulares.
            Verificamos la identidad de todos nuestros usuarios. Finanser es una
            marca registrada en INDECOPI.
          </p>
          <label>Todos los derechos reservados 2022. Finanser</label>
        </div>
      </div>
    </div>
  );
};

export default Footer;
