import React from 'react';
import { useCarruselPages } from './Carrusel';

const CarruselBody = ({ children }) => {
  const { currentPageIndex } = useCarruselPages(React.Children.count(children));
  return (
    <div className="cb-carrusel__content">
      {React.Children.toArray(children)[currentPageIndex]}
    </div>
  );
};

export default CarruselBody;
