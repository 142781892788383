import { api } from '../axios/api';
import { servicios } from '../helpers/index';

export const DETALLE_LIST = '[Detalle] Detalle lista';

export const getDetalle = (co) => (dispatch) => {
  api.get(`${servicios.DETALLES}/${co}`).then((response) => {
    dispatch({
      type: DETALLE_LIST,
      payload: { co, data: response },
    });
  });
};
