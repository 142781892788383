import * as fromApp from '../actions/app.action';

let initialState = {
  currentUser: null,
  captcha: null,
  headers: [],
  menues: [],
  menuesProcessing: false,
  selected: null,
  closeMenu: false,
  processing: false,
  captchaProcessing: true,
  loggedIn: false,
};

export const appReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case fromApp.APP_PROCESS:
      return {
        ...state,
        processing: true,
        captchaProcessing: true,
        error: null,
      };
    case fromApp.LOGIN_ON:
      return {
        ...state,
        currentUser: payload,
        loggedIn: true,
      };
    case fromApp.CAPTCHA_SUCCESS:
      return {
        ...state,
        captchaProcessing: false,
        processing: false,
        captcha: payload,
      };
    case fromApp.CAPTCHA_FAIL:
      return {
        ...state,
        processing: false,
        error: payload,
      };
    case fromApp.LOGIN_SUCCESS:
      return {
        ...state,
        processing: false,
        captchaProcessing: false,
        loggedIn: true,
        currentUser: payload,
        error: null,
      };
    case fromApp.LOGIN_ERROR:
      return {
        ...state,
        processing: false,
        error: payload,
      };
    case fromApp.LOGOUT:
      return {
        ...state,
        currentUser: null,
        captcha: null,
        headers: [],
        menues: [],
        menuesProcessing: false,
        selected: null,
        closeMenu: false,
        processing: false,
        captchaProcessing: true,
        loggedIn: false,
      };
    default:
      return state;
  }
};
