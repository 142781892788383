import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Formulario from '../../commons/Formulario';

import BlogItem from './BlogItem';
import BlogList from './BlogList';
import BlogFilter from './BlogFilter';

import { servicios } from '../../redux/helpers';
import { getDetalle } from '../../redux/actions/detalle.action';
import { TYPE_FILTER } from '../../redux/types/app.types';

import '../../css/pages/blog.css';
import '../../css/commons/carrusel.css';
import '../../css/commons/paginacion.css';

const Blog = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDetalle('CATEGORIA'));
    dispatch(getDetalle('SEGMENTO'));
  }, []);

  const initialState = {
    params: {
      st: '1',
      cc: null,
      cs: null,
    },
    rows: 6,
    serviceName: servicios.BLOG,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case TYPE_FILTER:
        return {
          ...state,
          params: {
            cc: action.payload.cc,
            cs: action.payload.cs,
          },
          selected: null,
        };
      default:
        return state;
    }
  };

  return (
    <>
      <Formulario reducer={reducer} initialState={initialState}>
        <div className="cb-blog-content">
          <BlogItem />
          <BlogList />
          <BlogFilter />
        </div>
      </Formulario>
    </>
  );
};

export default Blog;
