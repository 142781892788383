import React from 'react';
import HtmlToReact from 'html-to-react';

import Boton from '../../commons/Boton';
import Carrusel from '../../commons/Carrusel';
import CarruselBody from '../../commons/CarruselBody';
import CarruselNavigation from '../../commons/CarruselNavigation';
import ImagenOff from '../../commons/ImagenOff';
import { useTablaBody } from '../../commons/Formulario';

import { servicios } from '../../redux/helpers';

const BlogItem = () => {
  const { selected, setSelectedItem } = useTablaBody();

  const parse = () => {
    let htmlParser = new HtmlToReact.Parser();
    return htmlParser.parse(selected.co);
  };

  if (selected === null) {
    return null;
  }

  const formatearFecha = (fecha) => {
    let meses = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Setiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];

    const date = new Date(fecha);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    return `${meses[month]} ${day}, ${year}`;
  };

  return (
    <div className="cb-blog-seleccionado">
      <h1>{selected.ti}</h1>
      <span>{selected.si}</span>
      <div className="cb-blog-seleccionado-contenido">
        <div className="contenido">
          <div className="imagenes">
            {selected.adj && selected.adj.dtl && selected.adj.dtl.length > 0 ? (
              <Carrusel>
                <CarruselBody>
                  {selected.adj.dtl.map((x) => (
                    <img
                      key={x.co}
                      src={`${servicios.ARCHIVO}/${selected.adj.ar}/detalle/${x.co}`}
                      alt={x.ti}
                      loading={'eager'}
                    />
                  ))}
                </CarruselBody>
                <CarruselNavigation />
              </Carrusel>
            ) : (
              <div className="imagen nodisponible">
                <ImagenOff />
              </div>
            )}
          </div>
          <span>{formatearFecha(selected.fc)}</span>
          <div className="texto">{parse()}</div>
        </div>
      </div>
      <Boton
        label={'Regresar lista'}
        className="accion"
        onClick={() => setSelectedItem(null)}
      />
    </div>
  );
};

export default BlogItem;
