import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ color, size, width, svg, transparent, onClick, ...rest }) => {
  const style = {
    WebkitMask: `url(${svg}) no-repeat center`,
    mask: `url(${svg}) no-repeat center`,
    maskSize: 'contain',
    WebkitMaskSize: 'contain',
    backgroundColor: color,
    width: `${size}px`,
    height: `${size}px`,
    cursor: `${onClick ? 'pointer' : 'default'}`,
  };

  const styleTransparent = {
    background: `url(${svg}) no-repeat center`,
    width: `${width ? width : size}px`,
    height: `${size}px`,
    cursor: `${onClick ? 'pointer' : 'default'}`,
  };
  return (
    <div
      className="op-icon-global"
      style={transparent ? styleTransparent : style}
      onClick={() => {
        onClick ? onClick() : {};
      }}
      {...rest}
    />
  );
};

Icon.propTypes = {
  svg: PropTypes.any.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
  width: PropTypes.number,
  onClick: PropTypes.func,
  transparent: PropTypes.bool,
};

Icon.defaultProps = {
  size: 20,
  color: '#cccccc',
};

export default Icon;
