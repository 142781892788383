import * as fromCliente from '../actions/cliente.action';
import { clienteModel } from '../models/cliente.model';

let initialState = {
  processing: false,
  errors: clienteModel,
};

export const clienteReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case fromCliente.CLIENTE_PROCESS:
      return {
        ...state,
        processing: true,
        errors: clienteModel,
      };
    case fromCliente.CLIENTE_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        errors: clienteModel,
      };
    case fromCliente.CLIENTE_FAIL:
      return {
        ...state,
        processing: false,
        errors: payload,
      };
    default:
      return state;
  }
};
