import React from 'react';

import Titulo from '../components/Titulo';
import BloqueImagen from '../components/BloqueImagen';
import Icono from '../components/Icono';
import IconoText from '../components/IconoText';
import Perfil from '../components/Perfil';

import '../css/nosotros.css';

import logo from '../assets/nosotros/titulo.png';
import i2 from '../assets/nosotros/imagen2.png';
import i3 from '../assets/nosotros/vision.png';
import i4 from '../assets/nosotros/imagen4.png';
import i5 from '../assets/nosotros/somos.png';
import ip from '../assets/nosotros/perfil.png';
import c4 from '../assets/nosotros/icono4.svg';
import c5 from '../assets/nosotros/icono5.svg';
import c6 from '../assets/nosotros/network.svg';

const Nosotros = () => {
  return (
    <>
      <Titulo titulo="Nosotros" imagen={logo} />
      <BloqueImagen clase="cb-nosotros-mision somos" imagen={i5}>
        <label>¿Qui&eacute;nes somos?</label>
        <>
          <div className="texto">
            Somos Finanser, un punto de encuentro entre inversionistas y
            empresas que desean generar un impacto positivo en el planeta y la
            sociedad. Empresa registrada en la Superintendencia de Banca y
            Seguros - SBS (Resoluci&oacute;n N° 02961-2021).
          </div>
        </>
      </BloqueImagen>
      <BloqueImagen clase="cb-nosotros-vision" imagen={i3} alterno>
        <label>¿Cu&aacute;l es nuestra visi&oacute;n?</label>

        <div className="texto">
          Posicionarnos como una plataforma digital que apoya a Mipymes
          sostenibles comprometidas con el bienestar social y el cuidado del
          medio ambiente en sus necesidades de financiamiento para mejorar los
          resultados de sus operaciones y el alcance de su impacto en la
          sociedad.
        </div>
      </BloqueImagen>
      <BloqueImagen clase="cb-nosotros-mision" imagen={i2}>
        <label>¿Cu&aacute;l es nuestra misi&oacute;n?</label>
        <>
          <div className="texto">
            Facilitar experiencias financieras digitales, seguras y accesibles
            al convertir, de manera justa y equitativa, necesidades de
            financiamiento en oportunidades de inversi&oacute;n. Promovemos la
            sostenibilidad, la transparencia y la integridad en cada una de
            nuestras acciones, adem&aacute;s de nuestro compromiso por el
            desarrollo de los miembros de la familia CashBox.
          </div>
        </>
      </BloqueImagen>
      <BloqueImagen clase="cb-nosotros-valores">
        <>
          <label>¿Cu&aacute;les son nuestros valores?</label>
          <div className="valores">
            <span>Integridad</span>
            <span>Transparencia</span>
            <span>Responsabilidad</span>
            <span>Respeto</span>
            <span>Compromiso</span>
          </div>
        </>
      </BloqueImagen>
      <BloqueImagen clase="cb-nosotros-promo" imagen={i4} fondo>
        <div className="contenedor">
          <label>Nuestros principios</label>
          <div className="principios">
            <IconoText
              img={c4}
              nombre="Prop&oacute;sito evolutivo"
              texto="Buscamos conectar nuestro prop&oacute;sito organizacional con el prop&oacute;sito individual de quienes formamos parte. Tener  claridad de nuestro prop&oacute;sito nos permite competir con nosotros mismos y no con los dem&aacute;s."
            />
            <IconoText
              img={c5}
              nombre="Plenitud"
              texto="Nos esforzamos por lograr que todos los miembros de Finanser sintamos que estamos destinados a viajar juntos. Nos preocupamos en su crecimiento y desarrollo personal y laboral; los motivamos a que logren sus metas personales mientras logran las metas de la organizaci&oacute;n; de tal manera, que todo ello contribuya en su felicidad."
            />
            <IconoText
              img={c6}
              nombre="Autogesti&oacute;n"
              texto="Buscamos que la interacci&oacute;n entre nuestros miembros, sea lo m&aacute;s fluida, natural y &aacute;gil posible, ya que esto favorece la comunicaci&oacute;n, la creatividad, la innovaci&oacute;n y la reinvenci&oacute;n constante. Por ello, brindamos mayor autonom&iacute;a a nuestros equipos en sus decisiones y acciones siempre y cuando est&eacute;n alineados al prop&oacute;sito de la organizaci&oacute;n."
            />
          </div>
        </div>
      </BloqueImagen>
      <BloqueImagen
        clase="cb-nosotros-perfil"
        subtitulo="cashbox"
        titulo="Nuestro equipo"
      >
        <div className="perfiles">
          <Perfil
            img={ip}
            nombre="Cashbox 1"
            puesto="puesto"
            descripcion="Sed ut perspiciatis unde omnis iste natus error sit quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo."
          />
          <Perfil
            img={ip}
            nombre="Cashbox 1"
            puesto="puesto"
            descripcion="Sed ut perspiciatis unde omnis iste natus error sit quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo."
          />
          <Perfil
            img={ip}
            nombre="Cashbox 1"
            puesto="puesto"
            descripcion="Sed ut perspiciatis unde omnis iste natus error sit quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo."
          />
          <Perfil
            img={ip}
            nombre="Cashbox 1"
            puesto="puesto"
            descripcion="Sed ut perspiciatis unde omnis iste natus error sit quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo."
          />
        </div>
      </BloqueImagen>
    </>
  );
};

export default Nosotros;
