import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Texto from '../commons/Texto';
import Boton from '../commons/Boton';
import Icon from '../commons/Icon';
import { mensajeMini } from '../commons/Mensaje';
import Captcha from '../components/Captcha';

import { postClienteAction } from '../redux/actions/cliente.action';

import Open from '../assets/commons/open.svg';
import Close from '../assets/commons/close.svg';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const Registro = () => {
  const [{ nn, ap, cu, pc, rp, tx }, setUser] = useState({
    nn: null,
    ap: null,
    cu: null,
    pc: null,
    rp: null,
    tx: null,
  });
  const [terminos, setTerminos] = useState(false);
  const [privacidad, setPrivacidad] = useState(false);
  const [openCurrent, setOpenCurrent] = useState(false);
  const [openCurrentConfirm, setOpenCurrentConfirm] = useState(false);
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.app);
  const { processing, errors } = useSelector((state) => state.cliente);

  const handleChange = (item) => {
    const { name, value } = item;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (!terminos) {
      mensajeMini('Debe aceptar terminos y condiciones');
    } else if (!privacidad) {
      mensajeMini('Debe aceptar las politicas de privacidad');
    } else {
      dispatch(postClienteAction({ nn, ap, cu, pc, rp, tx }));
      handleChange({ name: 'tx', value: null });
    }
  };

  return (
    <div className="cb-content-login">
      <div className="cb-bloque cb-fondo-alterno">
        <label>Todos los derechos reservados 2021. Finanser.</label>
      </div>
      <div className="cb-bloque cb-login">
        <div className="content" style={{ padding: '20px 0' }}>
          <div className="logo"></div>
          <p className="icono"></p>
          <label className="title">Reg&iacute;strate</label>
          <span className="sub-title">Forma parte de la familia Finanser</span>
          <span className="texto">
            Completa la siguiente informaci&oacute;n
          </span>
          <Texto
            name="nn"
            value={nn}
            label="Nombres completos"
            tabIndex={1}
            onChange={handleChange}
            error={errors.nn}
            autoFocus
          />
          <Texto
            name="ap"
            value={ap}
            label="Apellidos completos"
            tabIndex={2}
            onChange={handleChange}
            error={errors.ap}
          />
          <Texto
            name="cu"
            value={cu}
            label="Correo electr&oacute;nico"
            tabIndex={3}
            onChange={handleChange}
            error={errors.cu}
          />
          <Texto
            name="pc"
            value={pc}
            label="Contrase&ntilde;a"
            tabIndex={4}
            type={`${openCurrent ? 'text' : 'password'}`}
            onChange={handleChange}
            action={() =>
              openCurrent ? (
                <Icon
                  svg={Open}
                  transparent
                  size={24}
                  width={45}
                  onClick={() => setOpenCurrent(false)}
                />
              ) : (
                <Icon
                  svg={Close}
                  transparent
                  size={24}
                  width={45}
                  onClick={() => setOpenCurrent(true)}
                />
              )
            }
            restrict="password"
            error={errors.pc}
          />
          <Texto
            name="rp"
            value={rp}
            label="Confirmar contrase&ntilde;a"
            tabIndex={5}
            type={`${openCurrentConfirm ? 'text' : 'password'}`}
            onChange={handleChange}
            action={() =>
              openCurrentConfirm ? (
                <Icon
                  svg={Open}
                  transparent
                  size={24}
                  width={45}
                  onClick={() => setOpenCurrentConfirm(false)}
                />
              ) : (
                <Icon
                  svg={Close}
                  transparent
                  size={24}
                  width={45}
                  onClick={() => setOpenCurrentConfirm(true)}
                />
              )
            }
            restrict="password"
            error={errors.rp}
          />
          <Captcha
            value={tx}
            onChange={handleChange}
            tabIndex={6}
            error={errors.tx}
          />
          <div className="op-form-group op-col-1 checkbox">
            <input
              id="txtCheckbox"
              type="checkbox"
              tabIndex={7}
              defaultValue={terminos}
              onChange={() => setTerminos(!terminos)}
            />
            <label htmlFor="txtCheckbox">
              Acepto los T&eacute;rminos y Condiciones de Finanser
            </label>
          </div>
          <div className="op-form-group op-col-1 checkbox">
            <input
              id="txtCheckboxPrivacidad"
              type="checkbox"
              tabIndex={8}
              defaultValue={privacidad}
              onChange={() => setPrivacidad(!privacidad)}
            />
            <label htmlFor="txtCheckboxPrivacidad">
              Acepto las Condiciones de uso y la Pol&iacute;tica de Privacidad
            </label>
          </div>
          <Boton
            label="Registrarme"
            onClick={handleSubmit}
            tabIndex={9}
            disabled={processing}
          />
          {error && <label className="cb-error">{error}</label>}
          <NavLink to="/" className="texto" style={{ marginTop: '30px' }}>
            <ChevronLeftIcon /> Atras
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Registro;
