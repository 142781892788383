import React from 'react';
import Proptypes from 'prop-types';

import '../css/commons/slide.css';

const Slide = ({ img, children }) => {
  return (
    <div className="cb-slide-content">
      <div className="cb-data">{children}</div>
      <div
        className="cb-image"
        style={{ backgroundImage: `url(${img})` }}
      ></div>
    </div>
  );
};

Slide.propTypes = {
  img: Proptypes.any.isRequired,
  children: Proptypes.any.isRequired,
};

export default Slide;
