import React from 'react';

import Boton from '../../commons/Boton';
import ImagenOff from '../../commons/ImagenOff';
import { useTablaBody } from '../../commons/Formulario';
import Paginacion from '../../commons/Paginacion';

import { servicios } from '../../redux/helpers';

const BlogList = () => {
  const { processing, data, setSelectedItem, selected } = useTablaBody();

  const viewDate = (d) => {
    const date = new Date(d);
    if (isNaN(date.getTime())) {
      return null;
    }
    const day = date.getDate();
    const nameShortDays = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
    return (
      <>
        <span>{day}</span>
        <span>{nameShortDays[date.getDay()]}</span>
      </>
    );
  };

  if (selected) {
    return null;
  }

  return (
    <>
      {processing ? (
        <>
          {[1, 2, 3, 4].map((i) => (
            <div key={i} className="cb-blog-item loader">
              <div className="imagen"></div>
              <div className="contenido"></div>
            </div>
          ))}
        </>
      ) : (
        <>
          <div className="cb-blog-items">
            {data.map((i, index) => (
              <div
                key={i.bl}
                className={`cb-blog-item${index === 0 ? ' main' : ''}`}
              >
                {i.adj && i.adj.dtl && i.adj.dtl.length > 0 ? (
                  <div className={'imagen'} onClick={() => setSelectedItem(i)}>
                    <img
                      src={`${servicios.ARCHIVO}/${i.adj.ar}/detalle/${i.adj.dtl[0].co}`}
                      loading={'eager'}
                      alt={i.ti}
                    />
                    {index !== 0 && (
                      <div className="fecha">{viewDate(i.fc)}</div>
                    )}
                  </div>
                ) : (
                  <div
                    className="imagen nodisponible"
                    onClick={() => setSelectedItem(i)}
                  >
                    <ImagenOff />
                    {index !== 0 && (
                      <div className="fecha">{viewDate(i.fc)}</div>
                    )}
                  </div>
                )}
                <div className="contenido">
                  {index === 0 ? (
                    <>
                      <h1>{i.ti}</h1>
                      <span>{i.si}</span>
                      <Boton
                        label="Leer m&aacute;s"
                        className={'accion'}
                        onClick={() => setSelectedItem(i)}
                      />
                    </>
                  ) : (
                    <h2>{i.ti}</h2>
                  )}
                </div>
              </div>
            ))}
          </div>
          <Paginacion />
        </>
      )}
    </>
  );
};

export default BlogList;
