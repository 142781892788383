import React, { useCallback, useEffect, useReducer } from 'react';
import { createContext } from 'react';

const carruselContext = createContext();

const useCarruselContext = () => {
  const context = React.useContext(carruselContext);
  if (!context) {
    throw new Error(
      '!El componente compuesto no puede ser renderizado fuera del carrusel'
    );
  }
  return context;
};

export const useCarruselNavigation = () => {
  const { goNextPage, goPrevPage, goSelectedDot, pages, currentPageIndex } =
    useCarruselContext();
  return {
    goNextPage,
    goPrevPage,
    goSelectedDot,
    currentPageIndex: currentPageIndex + 1,
    pages,
  };
};

export const useCarruselPages = (totalPages) => {
  const { setPages, currentPageIndex } = useCarruselContext();
  useEffect(() => {
    setPages(totalPages);
  }, [totalPages, setPages]);

  return {
    currentPageIndex,
  };
};

const defaultInitialState = {
  currentPageIndex: 0,
  pages: 0,
};

export const actionsCarrusel = {
  NEXT_PAGE: 'NEXT_PAGE',
  PREV_PAGE: 'PREV_PAGE',
  SELECTED_DOT: 'SELECTED_DOT',
  SET_PAGES: 'SET_PAGES',
};

const carruselReducer = (state, action) => {
  switch (action.type) {
    case actionsCarrusel.NEXT_PAGE:
      return { ...state, currentPageIndex: state.currentPageIndex + 1 };
    case actionsCarrusel.PREV_PAGE:
      return { ...state, currentPageIndex: state.currentPageIndex - 1 };
    case actionsCarrusel.SELECTED_DOT:
      return { ...state, currentPageIndex: action.payload };
    case actionsCarrusel.SET_PAGES:
      return { ...state, pages: action.payload };
    default:
      return state;
  }
};

const Carrusel = ({ children }) => {
  const [state, dispatch] = useReducer(carruselReducer, defaultInitialState);

  const goNextPage = () => {
    dispatch({ type: actionsCarrusel.NEXT_PAGE });
  };

  const goPrevPage = () => {
    dispatch({ type: actionsCarrusel.PREV_PAGE });
  };

  const goSelectedDot = (n) => {
    dispatch({ type: actionsCarrusel.SELECTED_DOT, payload: n });
  };

  const setPages = useCallback(
    (n) => {
      dispatch({ type: actionsCarrusel.SET_PAGES, payload: n });
    },
    [dispatch]
  );

  const context = {
    ...state,
    goNextPage,
    goPrevPage,
    goSelectedDot,
    setPages,
  };
  return (
    <carruselContext.Provider value={context}>
      <div className="cb-carrusel">{children}</div>
    </carruselContext.Provider>
  );
};
export default Carrusel;
