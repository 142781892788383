import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { logoutAction } from '../redux/actions/app.action';

const Dashboard = () => {
  const dispatch = useDispatch();
  return (
    <div>
      Dashboard <NavLink to={'/'}>Inicio</NavLink> {'   '}
      <NavLink
        to={'/'}
        onClick={() => {
          dispatch(logoutAction());
        }}
      >
        Salir
      </NavLink>
    </div>
  );
};

export default Dashboard;
