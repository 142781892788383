import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers/index';
import { isLogginIn } from './actions/app.action';

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(thunk))
);

isLogginIn()(store.dispatch, store.getState);
export default store;
