import React from 'react';

const Titulo = (props) => {
  return (
    <div className="cb-global-titulo">
      <div
        className="imagen"
        style={{ backgroundImage: `url('${props.imagen}')` }}
      ></div>
      <div className="overlay"></div>
      <span>{props.titulo}</span>
    </div>
  );
};

export default Titulo;
