import React from 'react';
import Proptypes from 'prop-types';
import { useCarruselNavigation } from './Carrusel';

const CarruselNavigation = ({ position, showButtons, showDots }) => {
  const { goNextPage, goPrevPage, goSelectedDot, currentPageIndex, pages } =
    useCarruselNavigation();

  if (pages <= 1) {
    return null;
  }

  return (
    <>
      {showButtons ? (
        <>
          <button
            className="cb-arrow prev"
            disabled={currentPageIndex === 1}
            onClick={() => goPrevPage()}
          ></button>
          <button
            className="cb-arrow next"
            disabled={currentPageIndex === pages}
            onClick={() => goNextPage()}
          ></button>
        </>
      ) : (
        <div className={`cb-carrusel__navigation ${position}`}>
          <div className={'cb-carrusel__dots'}>
            {[...Array(pages)].map((x, index) => (
              <span
                key={index}
                className={`${currentPageIndex - 1 === index ? 'active' : ''}`}
                onClick={() => goSelectedDot(index)}
              ></span>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

CarruselNavigation.propTypes = {
  position: Proptypes.oneOf(['left', 'top', 'right', 'bottom']),
  showDots: Proptypes.bool,
  showButtons: Proptypes.bool,
};

CarruselNavigation.defaultProps = {
  position: 'bottom',
  showDots: true,
};

export default CarruselNavigation;
