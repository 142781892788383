import PropTypes from 'prop-types';

import '../css/commons/formulario.css';
import { caracteresUnicode } from './Utilitarios';

import FindInPageIcon from '@material-ui/icons/FindInPage';
import Tooltip from './Tooltip';

const Texto = ({
  name,
  label,
  tabIndex,
  onChange,
  size,
  type,
  value,
  className,
  disabled,
  error,
  restrict,
  icon,
  action,
  search,
  onSearch,
  info,
  enabledPaste,
  ...rest
}) => {
  const handleKeyPress = (e) => {
    let re = null;
    switch (restrict) {
      case 'int':
        re = new RegExp('[0-9]');
        break;
      case 'money':
        re = new RegExp('[0-9.-]');
        break;
      default:
        re = new RegExp(`[a-zA-Z0-9-/.:+,;\_\\s${caracteresUnicode()}]`);
        break;
    }

    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div
      className={`op-form-group${size ? ' op-col-' + size : ''}${
        error ? ' error' : ''
      }${search ? ' searcheable' : ''}${icon ? ' icon' : ''}${
        action ? ' action' : ''
      }`}
    >
      <input
        name={name}
        className={`op-form${className ? ' ' + className : ''}`}
        value={value == null ? '' : value}
        tabIndex={tabIndex}
        required="required"
        disabled={disabled}
        type={type}
        onChange={(e) => onChange(e.target)}
        onKeyPress={(e) => handleKeyPress(e)}
        onPaste={(e) => (enabledPaste ? {} : e.preventDefault())}
        {...rest}
      />
      <label>{label}</label>
      {search && (
        <FindInPageIcon
          fontSize="large"
          style={{ cursor: 'pointer' }}
          onClick={() => onSearch()}
        />
      )}
      {icon && <div className="icon">{icon()}</div>}
      {action && <div className="action">{action()}</div>}
      <span className="bar bottom"></span>
      <span className="bar left"></span>
      <span className="bar right"></span>
      <span className="bar top"></span>
      {error && <span className="op-form-error">{error}</span>}
      {info && <Tooltip>{info}</Tooltip>}
    </div>
  );
};

Texto.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tabIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf([2, 3, 4]),
  type: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  restrict: PropTypes.string,
  search: PropTypes.bool,
  onSearch: PropTypes.func,
  focus: PropTypes.bool,
  info: PropTypes.string,
  icon: PropTypes.func,
  action: PropTypes.func,
  enabledPaste: PropTypes.bool,
};

Texto.defaultProps = {
  type: 'text',
  disabled: false,
  restrict: 'texto',
};

export default Texto;
